import React, { useState } from 'react'
import Button from '../components/button'
import Layout from '../components/layout'
import Seo from '../components/seo'

export default function MessageUs() {
    const [isSending, setIsSending] = useState(false)
    const [hasSent, setHasSent] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState("");

    function sendMessage(e) {
        e.preventDefault();

        setIsSending(true);
        setMessage(e.target.message.value);

        let form = new FormData();
        form.append("name", e.target.name.value);
        form.append("email", e.target.email.value);
        form.append("message", e.target.message.value);
        form.append("phone", e.target.phone.value);

        fetch("/api/submit", {
            method: "POST",
            body: form
        }).then(res => {
            setHasSent(true);
            setIsSending(false);
            if (res.status !== 200)
                setError(true);
        })
    }

    function ContactForm() {
        return (
            <div className="relative max-w-xl mx-auto">
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold tracking-tight text-stone-900 sm:text-4xl">Message Arlem</h2>
                    <p className="mt-4 text-lg leading-6 text-stone-500">
                        We'd love to hear from you! Send us a message below and we'll get back to you.
                    </p>
                </div>
                <div className="mt-12">
                    <form onSubmit={sendMessage} className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                        <div className="sm:col-span-2">
                            <label htmlFor="name" className="block text-lg font-bold text-stone-700">
                                Name
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="name"
                                    required={true}
                                    autoCapitalize="words"
                                    id="name"
                                    autoComplete="given-name"
                                    className="py-3 px-4 block w-full shadow-sm focus:ring-[#Cfa78e] focus:border-[#Cfa78e] border-stone-300 rounded-md"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="email" className="block text-lg font-bold text-stone-700">
                                Email
                            </label>
                            <div className="mt-1">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    className="py-3 px-4 block w-full shadow-sm focus:ring-[#Cfa78e] focus:border-[#Cfa78e] border-stone-300 rounded-md"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="phone" className="block text-lg font-bold text-stone-700">
                                Phone
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="tel"
                                    name="phone"
                                    id="phone"
                                    autoComplete="tel"
                                    className="py-3 px-4 block w-full focus:ring-[#Cfa78e] focus:border-[#Cfa78e] border-stone-300 rounded-md"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="message" className="block text-lg font-bold text-stone-700">
                                Message
                            </label>
                            <div className="mt-1">
                                <textarea
                                    id="message"
                                    name="message"
                                    autoCapitalize="sentences"
                                    required={true}
                                    rows={6}
                                    className="py-3 px-4 block w-full shadow-sm focus:ring-[#Cfa78e] focus:border-[#Cfa78e] border border-stone-300 rounded-md"
                                    defaultValue={''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <button
                                type="submit"
                                className="relative inline-block group w-full py-6"
                            >
                                <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 rounded translate-y-1 bg-[#Cfa78e] group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                                <span className="absolute inset-0 w-full h-full bg-white border-2 rounded border-[#Cfa78e] group-hover:bg-[#Cfa78e]"></span>
                                <span className="relative text-stone-900 group-hover:text-white group-hover:bg-[#Cfa78e] font-bold text-xl">
                                    {isSending ? <div className="flex justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="animate-spin h-6 w-6 text-[#Cfa78e] group-hover:text-white mr-2" viewBox="0 0 20 20" fill="currentColor">
                                            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                                        </svg>
                                        Sending...
                                    </div> :
                                        <span>Send</span>
                                    }
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    function MessageSent() {
        return (
            <div className="relative max-w-xl mx-auto">
                {!error ? <div className="text-center">
                    <h2 className="text-3xl font-extrabold tracking-tight text-stone-900 sm:text-4xl">Message sent!</h2>
                    <p className="mt-4 text-lg leading-6 text-stone-500">
                        Thank you very much for getting in touch with us. We will get back to you as soon as possible.
                    </p>
                    <div>
                        <div className="rounded-md bg-amber-50 p-4 text-lg mt-12 border-l-4 border-[#Cfa78e]">
                            <h3 className="font-bold text-stone-900">Your message</h3>
                            <div className="mt-2 text-sm text-stone-900">
                                <p>{message}</p>
                            </div>
                        </div>
                    </div>
                </div> :
                    <div className="text-center">
                        <h2 className="text-3xl font-extrabold tracking-tight text-stone-900 sm:text-4xl">Oops!</h2>
                        <p className="mt-4 text-lg leading-6 text-stone-500">
                            Hmm, we had trouble sending that message. Please try again.
                        </p>
                    </div>
                }
            </div>
        )
    }

    return (
        <Layout>
            <Seo title="Message us" description="We'd love to hear from you! Send us a message below and we'll get back to you." />
            <div className="text-left m-4 relative max-w-xl">
                <Button url="/" notFullWidth={true} small={true}>{`< Back`}</Button>
            </div>
            <div className="py-2 px-4 sm:px-6 lg:px-8">
                {hasSent ? <MessageSent /> : <ContactForm />}
            </div>
        </Layout>
    )
}