import * as React from "react"

export default function Button(props) {
    let { url, children, newWindow, notFullWidth, small } = props;

    let className = "relative inline-block group mb-4";

    if (!notFullWidth)
        className += " w-full";

    className += small ? " px-4 py-2" : " px-8 py-6";

    return (
        <a href={url} target={newWindow ? `_blank` : null} noreferrer="true" className={className}>
            <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 rounded translate-y-1 bg-[#Cfa78e] group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
            <span className="absolute inset-0 w-full h-full bg-white border-2 rounded border-[#Cfa78e] group-hover:bg-[#Cfa78e]"></span>
            <span className="relative text-stone-900 group-hover:text-white font-bold text-xl">{children}</span>
        </a>
    )
}